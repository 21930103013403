import React, { useState, useEffect } from 'react';
import './artist-stripe-refresh.scss';
import logo from '../old/assets/images/logo/logo-morphing-white.gif';

const ArtistStripeRefresh = () => {
	return (
		<div className="artistStripeRefresh">
			<img alt="quan" className="artistStripeRefresh_logo" src={logo} />
			<div className="artistStripeRefresh_wrapper">
				<h3>Stripe Onboarding Account link has expired!</h3>
				<p className="t3">
					Please open QUAN mobile app for artist and ask for new stripe onboarding account link in your profile
					settings.
				</p>
				{/* For deeplinking the domain needs to be different than the one on web */}
				<a href="https://dev.quan.global/artist-stripe-refresh">
					<div className="button">
						<p>OPEN APP</p>
					</div>
				</a>
			</div>
		</div>
	);
};

export default ArtistStripeRefresh;
